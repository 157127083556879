import { ADAPTER_EVENTS, CustomChainConfig, WALLET_ADAPTERS } from "@web3auth/base";
import { randomBytes } from "crypto-browserify";
import { ViewModel } from "../ViewModel";
import { Web3AuthProvider, CHAIN_CONFIG } from "../../services/Web3AuthProvider";
import { IWalletProvider } from "../../services/walletProvider";
import { LoginFailure } from "../../types";

export interface DIDLoginParams {
    salt: string;
    publicKey: string;
    address: string;
    timestamp?: number;
}

export interface DIDLoginMessage extends DIDLoginParams {
    nonce: string;
    timestamp: number;
    token: string;
}

export const prepareDIDLoginMessage = async (
    params: DIDLoginParams,
    wallet?: IWalletProvider
): Promise<DIDLoginMessage> => {
    const prepared: DIDLoginMessage = {
        ...params,
        nonce: randomBytes(32).toString("hex"),
        timestamp: new Date().getTime(),
        token: "",
    };
    const message = [
        prepared.salt,
        prepared.publicKey,
        prepared.nonce,
        prepared.timestamp,
    ].join("#");
    if (wallet?.personalSign) {
        let sign = await wallet.personalSign(prepared.publicKey, message);
        if (
            sign != undefined &&
            (sign.startsWith("0x") || sign.startsWith("0X"))
        ) {
            sign = sign.substring(2);
            prepared.token = Buffer.from(sign, "hex").toString("base64");
        }
    } else if (wallet?.signMessage) {
        const sign = await wallet.signMessage(
            new TextEncoder().encode(message).toString()
        );
        if (sign != undefined && sign.length > 0) {
            prepared.token = Buffer.from(sign).toString("base64");
        }
    }
    return prepared;
};

export class WalletLoginViewModel extends ViewModel {
    private _web3AuthProvider: Web3AuthProvider;
    private _errorMessage: string;
    private _isBusy: boolean;
    private _attemptLogin;
    private _loginOptions;
    private _signLogging: boolean;
    constructor(options) {
        super(options);
        const { loginOptions, attemptLogin, web3AuthProvider } = options;

        this._loginOptions = loginOptions;
        this._attemptLogin = attemptLogin;
        this._isBusy = false;
        this._errorMessage = "";
        this._web3AuthProvider = web3AuthProvider;
        this._signLogging = false;
        if (this._web3AuthProvider) {
            this._web3AuthProvider.off(ADAPTER_EVENTS.CONNECTED, this.signLogin);
            this._web3AuthProvider.on(ADAPTER_EVENTS.CONNECTED, this.signLogin);
        }
    }

    get isBusy() {
        return this._isBusy;
    }
    get errorMessage() {
        return this._errorMessage;
    }

    get web3AuthProvider() {
        return this._web3AuthProvider;
    }

    get signLogging() {
        return this._signLogging;
    }

    setBusy(status) {
        this._isBusy = status;
        this.emitChange("isBusy");
    }

    _showError(message) {
        this._errorMessage = message;
        this.emitChange("errorMessage");
    }

    walletLogin = async () => {
        this._web3AuthProvider.loginWithWalletConnect().catch((error) => {
            // wallet already connected
            if (error?.code === 5111) {
                this.signLogin();
            }
        });
    };

    login = async (identifier) => {
        this._errorMessage = "";
        this.emitChange("errorMessage");
        const status = await this._attemptLogin(
            this._loginOptions.wallet(identifier)
        );
        this._loginOptions.identifier=identifier
        this._loginOptions.loginMethod = this.login;
        let error = "";
        switch (status) {
            case LoginFailure.Credentials:
                error = this
                    .i18n`Your username and/or password don't seem to be correct.`;
                break;
            case LoginFailure.Connection:
                error = this
                    .i18n`Can't connect to ${this._loginOptions.homeserver}.`;
                break;
            case LoginFailure.Unknown:
                error = this
                    .i18n`Something went wrong while checking your login and password.`;
                break;
        }
        if (error) {
            this._showError(error);
        }
    }

    async logout() {
        await this._web3AuthProvider?.web3Auth?.logout();
    }

    signLogin = async () => {
        if (
            this._web3AuthProvider.provider &&
            this._web3AuthProvider.web3Auth?.cachedAdapter ===
                WALLET_ADAPTERS.WALLET_CONNECT_V1
        ) {
            this._signLogging = true;
            this.emitChange("signLogging");
            const [address] = await this._web3AuthProvider.provider?.getAccounts();
            if (address) {
                let chain = await this._web3AuthProvider.provider?.getChain();
                if (chain == "ethereum") {
                    chain = "eth";
                } else if (chain == "solana") {
                    chain = "sol";
                }
                try {
                    const prepared = await prepareDIDLoginMessage(
                        {
                            publicKey: address,
                            salt: "Sending.Me Account",
                            address: `did:${chain}:devnet:${address}_did`,
                        },
                        this._web3AuthProvider.provider
                    );
                    const identifier = {
                        ...prepared,
                        type: "m.id.thirdparty",
                        medium: "did",
                        publicKey: address,
                    };
                    await this.login(identifier);
                } catch (error) {
                    // await this.logout()
                    this._signLogging = false;
                    this.emitChange("signLogging");
                    throw error;
                } finally {
                    this._signLogging = false;
                    this.emitChange("signLogging");
                }
            }
        }
    };
}
