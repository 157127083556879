/* eslint-disable no-undef */

// import base from "base-x";
import { LoginFailure } from "../../matrix/Client.js";
import { ViewModel } from "../ViewModel";

export class ToursLoginViewModel extends ViewModel {
    constructor(options) {
        super(options);
        const { loginOptions, attemptLogin } = options;
        this._loginOptions = loginOptions;
        this._attemptLogin = attemptLogin;
        this._isBusy = false;
        this._errorMessage = "";
    }

    get isBusy() {
        return this._isBusy;
    }
    get errorMessage() {
        return this._errorMessage;
    }

    setBusy(status) {
        this._isBusy = status;
        this.emitChange("isBusy");
    }

    _showError(message) {
        this._errorMessage = message;
        this.emitChange("errorMessage");
    }

    async walletconnect() {
        console.log(this._wallet);
    }

    async base64_arraybuffer(data) {
        // Use a FileReader to generate a base64 data URI
        var base64url = await new Promise((r) => {
            var reader = new FileReader();
            reader.onload = () => r(reader.result);
            reader.readAsDataURL(new Blob([data]));
        });

        /*
    The result looks like 
    "data:application/octet-stream;base64,<your base64 data>", 
    so we split off the beginning:
    */
        return base64url.split(",", 2)[1];
    }

    async getLoginParam() {
        let loginParams = {};
        if (globalThis.ethereum) {
            var accounts = await globalThis.ethereum.request({
                method: "eth_requestAccounts",
            });
            var account = accounts[0];
            if (account) {
                let randomU8 = crypto.getRandomValues(new Uint8Array(32));
                var nonce = base("0123456789abcdef").encode(randomU8);

                var prepared = {
                    publicKey: account,
                    salt: "Sending.Me Account",
                    address: `did:eth:mainnet:${account}_did`,
                    nonce,
                    timestamp: new Date().getTime(),
                };
                var message = [
                    prepared.salt,
                    prepared.publicKey,
                    prepared.nonce,
                    prepared.timestamp,
                ].join("#");

                try {
                    var from = prepared.publicKey;
                    var msg = `0x${Array.prototype.map
                        .call(new TextEncoder().encode(message), (x) =>
                            ("00" + x.toString(16)).slice(-2)
                        )
                        .join("")}`;
                    let sign = await globalThis.ethereum.request({
                        method: "personal_sign",
                        params: [msg, from, ""],
                    });
                    if (sign.startsWith("0x") || sign.startsWith("0X")) {
                        sign = sign.substring(2);
                        var tokenU8 = base("0123456789abcdef").decode(sign);
                        let token = await this.base64_arraybuffer(tokenU8);
                        prepared.token = token
                            .replace(/\+/g, "-") // Convert '+' to '-'
                            .replace(/\//g, "_");

                        var identifier = {
                            ...prepared,
                            type: "m.id.thirdparty",
                            medium: "did",
                        };
                        loginParams = {
                            ...identifier,
                        };
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        return loginParams;
    }

    async metaMaskLogin() {
        var loginParams = await this.getLoginParam();
        this.login(loginParams);
    }

    async login(identifier) {
        this._errorMessage = "";
        this.emitChange("errorMessage");
        const status = await this._attemptLogin(
            this._loginOptions.tours(identifier)
        );
        let error = "";
        switch (status) {
            case LoginFailure.Credentials:
                error = this
                    .i18n`Your username and/or password don't seem to be correct.`;
                break;
            case LoginFailure.Connection:
                error = this
                    .i18n`Can't connect to ${this._loginOptions.homeserver}.`;
                break;
            case LoginFailure.Unknown:
                error = this
                    .i18n`Something went wrong while checking your login and password.`;
                break;
        }
        if (error) {
            this._showError(error);
        }
    }
}
