import { TemplateView } from "../../general/TemplateView";
import { ViewModel } from "../../../../../lib";
import { DisplayNameView } from "./DisplayNameView";
import { UserAvatarView } from "./UserAvatarView";

export class ProfileView extends TemplateView<ViewModel> {
    render(t, vm) {
        return t.div({ className: "ProfileScreen" }, [
            t.div({ className: "ProfileView" }, [
                t.mapView(
                    (vm) => vm.displayNameViewModel,
                    (vm) => vm? new DisplayNameView(vm): null
                ),
                t.mapView(
                    (vm) => vm.userAvatarViewModel,
                    (vm) => vm? new UserAvatarView(vm): null
                ),
            ]),
        ]);
    }
}
