import { InlineTemplateView, TemplateView } from "../../general/TemplateView";
import { ViewModel } from "../../../../../lib";
import { ListView } from "../../general/ListView";

export class DisplayNameView extends TemplateView<ViewModel> {
    render(t, vm) {
        const tagInput = t.input({
            id: "username",
            type: "text",
            placeholder: vm.i18n`Username`,
            onInput: (event) => {
                vm.setDisplayName(event.target.value);
            },
        }) as HTMLInputElement;

        t.mapSideEffect(
            (vm) => vm.displayName,
            (name, _oldName) => (tagInput.value = name)
        );

        return t.div({ className: "name-container" }, [
            t.div(
                { className: "title" },
                vm.i18n`How do you want to be named?`
            ),
            t.form(
                {
                    className: "form",
                    autocomplete: "off",
                    onSubmit: (event) => {
                        event.preventDefault();
                        vm.saveDisplayName();
                    },
                },
                [
                    t.div({ className: "form-item" }, [tagInput]),
                    t.div(
                        { className: "name-option-title" },
                        vm.i18n`Use ENS for name`
                    ),
                    t.mapView(
                        vm=>vm.ensList,
                        (ensList)=> {
                            const view = ensList && ensList.length>0 ? new InlineTemplateView(vm, (t, vm) => {
                                return t.div({ className: "name-option-list" }, ensList.map(ensName=>{
                                    return t.map(vm => vm.displayName, (displayName, t, vm) => {
                                        let active;
                                        if(ensName === displayName){
                                            active = true; 
                                        }
                                        return t.div( {
                                            className: `name-option-button ${
                                                ensName === vm.displayName ? "active" : ""
                                            }`,
                                            onClick: () => {
                                                vm.setDisplayName(ensName);
                                            },
                                        },
                                        ensName)
                                    })
                                    
                                    
                                }))
                                
                            }) : null;
                            return view;
                        })
                    ,
                    t.div({ className: "next-button" }, [
                        t.button({
                            className: "next-button-icon",
                            type: "submit",
                        }),
                    ]),
                ]
            ),
        ]);
    }
}
