/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import queryString from 'query-string'
import {ViewModel} from "../ViewModel";
import {xhrRequest} from '../../platform/web/dom/request/xhr.js'

export interface ILoginParams {
    identifier?: object;
    password?: string;
    token?: string;
    device_id?: string;
    initial_device_display_name?: string;
    auth?: {
        session?: string;
        token?: string;
    };
}

export class ActiveCodeViewModel extends ViewModel {
    private _errorMessage: string;
    private session: string;
    private loginOptions;
    private _loginMethod;
    private loginAfter;

    constructor(options) {
        super(options);
        this.loginAfter = options.loginAfter;
        this._loginMethod = options.loginMethod;
        this.loginOptions=options.loginOptions;
        this.session = options.session
        this._errorMessage = ''
    }

    get errorMessage(){
        return this._errorMessage
    }

    async onInput() {
        this._errorMessage = '';
        this.emitChange('errorMessage')
    }

    async postToken (params: any) {

        const homeserver = this.platform.config.defaultHomeServer;
        const path = '/_matrix/client/r0/auth/org.matrix.msc3231.login.registration_token/fallback/web'
        const _params = queryString.stringify(params)
        try {
            const res = await xhrRequest(
                `${homeserver}${path}?${_params}`,
                {
                    method: "POST",
                }).response();
            const { identifier } = this.loginOptions
            identifier.auth = { session: this.session };
            const loginData = await this._loginMethod.activeCodeLogin(identifier)
            await this.loginAfter(loginData);
        } catch (error) {
            console.log(error)
            this._errorMessage = this.i18n`Invitation code error`;
            this.emitChange('errorMessage')
        }
    }

    onSubmit = (token) => {
        if (token.length !== 6) {
            this._errorMessage = this.i18n`Invitation code error`;
            this.emitChange('errorMessage')
            return;
        }
        this.postToken({
            session: this.session,
            token,
        })
        this._errorMessage = ''
        this.emitChange('errorMessage')
    };

}
