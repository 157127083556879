import { Web3AuthProvider } from "../../services/Web3AuthProvider";
import { LoginFailure } from "../../types";
import { randomBytes } from "crypto-browserify";
import { ViewModel } from "../ViewModel";
import {
    DIDLoginMessage,
    DIDLoginParams,
    prepareDIDLoginMessage,
} from "./WalletLoginViewModel";
import { ADAPTER_EVENTS, WALLET_ADAPTERS } from "@web3auth/base";

export class MetaMaskLoginViewModel extends ViewModel {
    private _web3AuthProvider: Web3AuthProvider;
    private _errorMessage: string;
    private _isBusy: boolean;
    private _attemptLogin;
    private _loginOptions;
    constructor(options) {
        super(options);
        const { loginOptions, attemptLogin, web3AuthProvider } = options;

        this._loginOptions = loginOptions;
        this._attemptLogin = attemptLogin;
        this._isBusy = false;
        this._errorMessage = "";
        this._web3AuthProvider = web3AuthProvider;
        if (this._web3AuthProvider) {
            this._web3AuthProvider.removeListener(ADAPTER_EVENTS.CONNECTED, this.signLogin);
            this._web3AuthProvider.addListener(ADAPTER_EVENTS.CONNECTED, this.signLogin);
        }
    }

    get isBusy() {
        return this._isBusy;
    }
    get errorMessage() {
        return this._errorMessage;
    }

    setBusy(status) {
        this._isBusy = status;
        this.emitChange("isBusy");
    }

    _showError(message) {
        this._errorMessage = message;
        this.emitChange("errorMessage");
    }

    signLogin = async () => {
        if (
            this._web3AuthProvider.provider &&
            this._web3AuthProvider.web3Auth?.cachedAdapter ===
                WALLET_ADAPTERS.METAMASK
        ) {
            const [address] =
                await this._web3AuthProvider.provider?.getAccounts();
            if (address) {
                let chain =
                    await this._web3AuthProvider.provider?.getChain();
                if (chain == "ethereum") {
                    chain = "eth";
                } else if (chain == "solana") {
                    chain = "sol";
                }
                try {
                    const prepared = await prepareDIDLoginMessage(
                        {
                            publicKey: address,
                            salt: "Sending.Me Account",
                            address: `did:${chain}:devnet:${address}_did`,
                        },
                        this._web3AuthProvider.provider
                    );
                    const identifier = {
                        ...prepared,
                        type: "m.id.thirdparty",
                        medium: "did",
                        publicKey: address,
                        ...this.options.nac?{
                            invitation: { token: this.options.nac },
                        }:{}
                    };
                    await this.login(identifier);
                } catch (error) {
                    await this._web3AuthProvider?.web3Auth?.logout();
                    throw error;
                }
            }
        }
    }

    async metaMaskLogin() {
        try {
            await this._web3AuthProvider.login(WALLET_ADAPTERS.METAMASK, undefined, undefined, {nac: this.options.nac, invitePath: this.options.invitePath});
        } catch (error) {
            this.signLogin()      
        }
    }

    login = async (identifier) => {
        this._errorMessage = "";
        this.emitChange("errorMessage");
        const status = await this._attemptLogin(
            this._loginOptions.metaMask(identifier)
        );
        this._loginOptions.identifier=identifier
        this._loginOptions.loginMethod = this.login;
        let error = "";
        switch (status) {
            case LoginFailure.Credentials:
                error = this
                    .i18n`Your username and/or password don't seem to be correct.`;
                break;
            case LoginFailure.Connection:
                error = this
                    .i18n`Can't connect to ${this._loginOptions.homeserver}.`;
                break;
            case LoginFailure.Unknown:
                error = this
                    .i18n`Something went wrong while checking your login and password.`;
                break;
        }
        if (error) {
            this._showError(error);
        }
    }
}
