import { BrowserType, DeepLink, PlatformType } from "./deeplink";
// import { getVectorConfig } from '../getconfig';

export default class WebDeepLink implements DeepLink {
    browser: BrowserType;
    type: PlatformType;
    fallbackWebUrl: string;
    storeUrl: string;
    constructor(props?: any) {
        this.browser = "default browser";
    }
    openDapp = (uri: string) => {
        const url = new URL(uri);
        const params = uri.substring(
            uri.indexOf(url.hostname) +
                url.hostname.length +
                url.pathname.length,
        );
        const domain = url.hostname.replace(/[.]/g, "-");
        location.href = `${location.origin}#/markets/${domain}?open_panel=true&url=${url.origin}${url.pathname}${encodeURIComponent(params)}`;
    };
    getStoreURL(): string {
        throw new Error("Method not implemented.");
    }
    open(uri: string) {
        location.href = uri;
    }
}
