
import Android, { IAndroid } from './android';
import MacOS, { IMacOS } from './ios';
import Web from './web';

export type PlatformType = 'ios' | 'android';

export interface DeepLink {
    fallbackWebUrl?: string;
    storeUrl: string;
    browser: BrowserType;
    type: PlatformType;
    open: (uri: string) => void;
    openDapp: (uri: string) => void;
    getStoreURL(): string;
}

export type IDeeplinkSetting = {
    androidDisabled: boolean;
    android?: {
        appId: string;
        fallbackWebUrl: string;
        storeUrl: string;
    };
    iOS?: {
        fallbackWebUrl: string;
        storeUrl: string;
    };
    delay: number;
    delta: number;
    fallback?: boolean;
    fallbackToWeb?: boolean;
};

export type BrowserType = 'Safari' | 'default browser';

export class DeepLinkApp {
    platform: DeepLink;
    private settings?: IDeeplinkSetting;
    private timeout: NodeJS.Timeout;
    private fallbackWebUrl: string;
    constructor(props: {settings?: IDeeplinkSetting, iso?: IMacOS, android?: IAndroid} = {}) {
        this.settings = {
            fallbackToWeb: true,
            androidDisabled: false,
            fallback: true,
            delay: 3000,
            delta: 500,
            ...props.settings,
        };
        this.fallbackWebUrl = `${location.origin}/mobile_guide`;
        if (this.isIos) {
            this.platform = new MacOS(props.iso);
        } else if (this.isAndroid) {
            this.platform = new Android(props.android);
        } else {
            this.platform = new Web();
        }
    }

    public get isIos(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
    public get isAndroid(): boolean {
        return /Android/.test(navigator.userAgent);
    }

    public get isMobile(): boolean {
        return this.isAndroid || this.isIos;
    }

    setup=({ ios, android }: {ios?: IMacOS, android?: IAndroid})=>{
        if (this.isIos) {
            this.platform = new MacOS(ios);
        } else if (this.isAndroid) {
            this.platform = new Android(android);
        } else {
            this.platform = new Web();
        }
    };

    getWebLink = () => {
        return this.fallbackWebUrl || location.href;
    };

    getStoreLink = () => {
        return this.platform.getStoreURL();
    };

    openFallback = (ts) => {
        return () => {
            const link = (this.settings?.fallbackToWeb) ? this.getWebLink() : this.getStoreLink();
            if(this.settings){
                const wait = this.settings.delay + this.settings.delta;
                if (typeof link === "string" && (Date.now() - ts) < wait) {
                    window.location.href = link;
                }
            }
        };
    };

    open = (uri: string) => {
        if (this.settings && (this.settings.fallback || this.settings.fallbackToWeb)) {
            this.timeout = setTimeout(this.openFallback(Date.now()), this.settings.delay);
        }
        this.platform.open(uri);
        // return true;
    };
    openDapp = (uri: string) => {
        if (this.settings && (this.settings.fallback|| this.settings.fallbackToWeb)) {
            this.timeout = setTimeout(this.openFallback(Date.now()), this.settings.delay);
        }
        if(this.platform){
            this.platform.openDapp(uri);
        }
    };

    private static internalInstance: DeepLinkApp;

    public static get instance(): DeepLinkApp {
        if (!DeepLinkApp.internalInstance) {
            DeepLinkApp.internalInstance = new DeepLinkApp();
            globalThis.DeepLinkApp = DeepLinkApp;
        }
        return DeepLinkApp.internalInstance;
    }
}
