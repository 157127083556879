/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { ILogItem } from "../../logging/types";
import { HomeServerApi } from "../net/HomeServerApi.js";
import { ILoginMethod } from "./LoginMethod";

export class WalletLoginMethod implements ILoginMethod {
    private readonly _identifier: any;
    public readonly homeserver: string;
    private _hsApi;
    private _deviceName;
    constructor({
        identifier,
        homeserver,
    }: {
        identifier: any;
        homeserver: string;
    }) {
        this._identifier = identifier;
        this.homeserver = homeserver;
    }

    async activeCodeLogin(_identifier){
        return await this._hsApi.walletLogin(_identifier, this._deviceName).response();
     }
 

    async login(
        hsApi: HomeServerApi,
        deviceName: string,
        log: ILogItem
    ): Promise<Record<string, any>> {
        this._hsApi = hsApi;
        this._deviceName = deviceName;
        return await hsApi
            .walletLogin(this._identifier, deviceName, {
                log,
            })
            .response();
    }
}
