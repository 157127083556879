
            // aws-sdk requires global to exist
            import './polyfills'
            import { main } from "./main";
            import { Platform } from "./Platform";
            import configURL from "./assets/config.json?url";
            import assetPaths from "./sdk/paths/vite";
            if (import.meta.env.PROD) {
                assetPaths.serviceWorker = "sw.js";
            }
            const platform = new Platform({
                container: document.body,
                assetPaths,
                configURL,
                options: { development: import.meta.env.DEV },
            });
            main(platform);
        