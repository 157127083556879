import { Buffer } from 'buffer'

// aws-sdk requires global to exist
(window as any).global = window;
(window as any).Buffer = Buffer;
(window as any).process = {
   env: { DEBUG: undefined },
   nextTick: function() {
     return null;
   }
};