/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import queryString from "query-string";
import { Options as BaseOptions, ViewModel } from "./ViewModel";
import { SegmentType } from "./navigation/index";
import { HomeServerApi, QueryDict } from "../matrix/net/HomeServerApi";

type Options = { sessionId: string } & BaseOptions;

export type AppInfo = {
    appLogo?: string;
    appName?: string;
    appUrl?: string;
};

export class AuthViewModel extends ViewModel<SegmentType, Options> {
    private _busy: boolean;
    private _showConfirm: boolean;
    private _error?: Error;
    private _appInfo: AppInfo;

    constructor(options: Options) {
        super(options);
        this._busy = false;
        this._showConfirm = true;
        this._error = undefined;
        this.getAppInfo();
    }

    get showConfirm(): boolean {
        return this._showConfirm;
    }

    get busy(): boolean {
        return this._busy;
    }

    get cancelUrl(): string | undefined {
        return this.urlCreator.urlForSegment("session", true);
    }

    get appInfo(): AppInfo {
        return this._appInfo;
    }

    get status(): string {
        if (this._error) {
            return this
                .i18n`Could not log out of device: ${this._error.message}`;
        } else {
            return this.i18n`Logging out… Please don't close the app.`;
        }
    }

    onAuthorize = async () => {
        this._busy = true;
        this.emitChange("busy");
        const query = this.navigation.path.get("query");
        if (query) {
            const params = queryString.parse(query.value);
            const {
                redirect_uri: redirectUri,
                client_id: clientId,
                ...others
            } = params;
            const res = await (await this._hsApi()).authorize({
                redirectUri,
                clientId,
            } as QueryDict).response();
            if (res && res.authorization_code) {
                others.authorization_code = res.authorization_code;
                others.client_id = clientId;
                console.log(redirectUri, others);
                location.href = `${decodeURIComponent(redirectUri as string)}?${queryString.stringify(others)}`;
            }
        }
        this._busy = false;
        this.emitChange("busy");
    };

    async _hsApi() {
        const sessionInfo = await this.platform.sessionInfoStorage.get(
            this.options.sessionId
        );
        if (!sessionInfo) {
            this.navigation.push('session', true);
            throw new Error(
                `Could not find session for id ${this.options.sessionId}`
            );
        }
        const hsApi = new HomeServerApi({
            homeserver: sessionInfo.homeServer,
            accessToken: sessionInfo.accessToken,
            request: this.platform.request,
        });
        return hsApi;
    }

    async getAppInfo() {
        this._busy = true;
        this.emitChange("busy");
        try {
            const hsApi = await this._hsApi();
            const query = this.navigation.path.get("query");
            if (query?.value && hsApi) {
                this._appInfo = await hsApi.getAuthAppInfo(query?.value).response() as AppInfo;
            }
        } catch (err) {}
        this._busy = false;
        this.emitChange("busy");
    }
}
