import { ViewModel } from "../../../../lib";
import { TemplateView } from "../general/TemplateView";

export class ActiveCodeView extends TemplateView<ViewModel> {
    private index = 0;
    private codes = ["", "", "", "", "", ""];

    render(t, vm) {
        const codeTags = this.codes.map((code, index) => {
            return t.input({
                className: "code",
                value: code,
                oninput: (event) => {
                    oninput(event, index);
                },
                onfocus: () => {
                    this.index = index;
                    console.log(this.index);
                },
                onkeydown: (event) => {
                    if (event.keyCode === 8) {
                        event.preventDefault();
                        event.target.value = "";
                        this.codes[index] = ""
                        const prev = this.index - 1;
                        if (prev > -1) {
                            codeTags[this.index - 1].focus();
                        }
                        vm.onInput();
                    }
                },
            });
        });
        const oninput = (event, index) => {
            console.log(event);
            let value = event.target.value;
            if (value.length > 1) {
                value =
                    value.split("").find((v) => v !== this.codes[index]) ||
                    this.codes[index];
                event.target.value = value;
            }
            this.codes[index] = value;
            const next = index + 1;
            if (value && next < this.codes.length) {
                codeTags[index + 1]?.focus();
            }
            vm.onInput();
        };

        return t.div({ className: "ActiveCodeView" }, [
            t.div(
                { className: "title" },
                vm.i18n`Input invitation code to join Sending.Me`
            ),
            t.form({ className: "codes", onSubmit: (event)=>{
                event.preventDefault();
                vm.onSubmit(this.codes.join(""))
            } }, [
                ...codeTags,
                t.div({ className: "button-row" }, [
                    t.button(
                        { className: "button-action primary", type: "submit" },
                        vm.i18n`Join`
                    ),
                ]),
            ]),
            t.if(
                (vm) => vm.errorMessage,
                (t, vm) => t.p({className: "error"}, vm.i18n(vm.errorMessage))
            ),
        ]);
    }
}
