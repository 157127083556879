/*
Copyright 2020 Bruno Windels <bruno@windels.cloud>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { spinner } from "../common.js";
import { TemplateView, InlineTemplateView } from "../general/TemplateView";
import { CompleteSSOView } from "./CompleteSSOView.js";
import { MetaMaskLoginView } from "./MetaMaskLoginView.js";
import { QRCodeLoginView } from "./QRCodeLoginView";
import { SessionLoadStatusView } from "./SessionLoadStatusView.js";
import { ToursLoginView } from "./ToursLoginView.js";
import { WalletLoginView } from "./WalletLoginView.js";
import { ActiveCodeView } from "./ActiveCodeView";
export class LoginView extends TemplateView {
    render(t, vm) {
        const disabled = (vm) => vm.isBusy;

        const walletButtons = new InlineTemplateView(vm, t => {
            return t.div({ className: "h5LoginBtn" }, [
                t.mapView(
                    (vm) => vm.metaMaskLoginViewModel,
                    (vm) =>
                        vm ? new MetaMaskLoginView(
                                  vm
                              )
                            : null
                ),
                t.mapView(
                    (vm) => vm.walletLoginViewModel,
                    (vm) =>
                        vm
                            ? new WalletLoginView(vm)
                            : null
                ),
            ]);
        })

        return t.div({ className: "login-wrapper" }, [
            t.if(
                (vm) => vm.isExtend,
                (t) =>
                    t.div({ className: "mask" }, [
                        t.div(
                            {
                                onClick: () => vm.openLogin(),
                                className: "open",
                            },
                            "click to get started"
                        ),
                        t.div(
                            { onClick: () => vm.reload(), className: "reload" },
                            "reload"
                        ),
                    ])
            ),
            t.if(
                (vm) => vm.isPcLogin,
                (t) =>
                    t.div({ className: "PcLogin" }, [
                        t.div({ className: "PcLogo" }),
                        t.div({ className: "pcLoginBox" }, [
                            t.div(
                                { className: "PcWelcome" },
                                vm.i18n`Welcome to Sending me`
                            ),
                            t.div(
                                { className: "PcNotice" },
                                // eslint-disable-next-line no-irregular-whitespace
                                vm.i18n`Sending me is a Decentralized Instant Messenger which create a social area of freedom, security and clean.`
                            ),
                            t.div({ className: "pcLoginBtn" }, [
                                t.mapView(
                                    (vm) => vm.metaMaskLoginViewModel,
                                    (vm) =>
                                        vm ? new MetaMaskLoginView(vm) : null
                                ),
                                t.mapView(
                                    (vm) => vm.walletLoginViewModel,
                                    (vm) =>
                                        vm ? new WalletLoginView(vm) : null
                                ),
                            ]),
                            t.mapView(
                                (vm) => vm.loadViewModel,
                                (loadViewModel) =>
                                    loadViewModel
                                        ? new SessionLoadStatusView(
                                              loadViewModel
                                          )
                                        : null
                            ),
                            t.mapView(
                                (vm) => vm.qRCodeLoginViewModel,
                                (vm) => (vm ? new QRCodeLoginView(vm) : null)
                            ),
                            t.mapView(
                                (vm) => vm.toursLoginViewModel,
                                (vm) => (vm ? new ToursLoginView(vm) : null)
                            ),
                        ]),
                    ])
            ),
            t.if(
                (vm) => !vm.isPcLogin,
                (t) =>
                    t.div({ className: "PreSessionScreen" }, [
                        t.div({ className: "logo" }),
                        t.mapView(
                            (vm) => vm.completeSSOLoginViewModel,
                            (vm) => (vm ? new CompleteSSOView(vm) : null)
                        ),

                        t.if(
                            (vm) => vm.isFetchingLoginOptions,
                            (t) =>
                                t.div(
                                    { className: "LoginView_query-spinner" },
                                    [
                                        spinner(t),
                                        t.p(
                                            "Fetching available login options..."
                                        ),
                                    ]
                                )
                        ),

                        t.mapView(
                            (vm) => vm.activeCodeViewModel,
                            (vm) =>
                                vm
                                    ? new ActiveCodeView(vm)
                                    : walletButtons
                        ),
                        t.mapView(
                            (vm) => vm.loadViewModel,
                            (loadViewModel) =>
                                loadViewModel
                                    ? new SessionLoadStatusView(loadViewModel)
                                    : null
                        ),
                    ])
            ),
        ]);
    }
}