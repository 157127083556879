import { SafeEventEmitterProvider } from '@web3auth/base';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { getV4TypedData } from './data';
import { IWalletProvider } from './walletProvider';

const ethProvider = (
  provider: SafeEventEmitterProvider,
  uiConsole: (...args: unknown[]) => void
): IWalletProvider => {
  const getAccounts = async (): Promise<any> => {
    try {
      const web3 = new Web3(provider as any);
      const accounts = await web3.eth.getAccounts();
      uiConsole('Eth accounts', accounts);
      return accounts;
    } catch (error) {
      console.error('Error', error);
      uiConsole('error', error);
    }
  };

  const getBalance = async () => {
    try {
      const web3 = new Web3(provider as any);
      const accounts = await web3.eth.getAccounts();
      console.log('accounts', accounts);
      const balance = await web3.eth.getBalance(accounts[0]);
      uiConsole('Eth balance', balance);
    } catch (error) {
      console.error('Error', error);
      uiConsole('error', error);
    }
  };

  const personalSign = async (from: string, msg: string): Promise<any> => {
    if (provider) {
      return provider.request({
        method: 'personal_sign',
        params: [msg, from, ''],
      });
    }
  };

  const getEnsByAddress = async (address?: string) => {
    const _provider = new ethers.providers.Web3Provider(provider)
    if(address){
      return await _provider.lookupAddress(address);
    }
    const [selfAddress] = await getAccounts();
    return await _provider.lookupAddress(selfAddress);

  }

  const getChain = async (): Promise<string> => {
    return 'ethereum';
  }

  const signMessage = async (message: string) => {
    try {
      const pubKey = (await provider.request({
        method: 'eth_accounts',
      })) as string[];
      const web3 = new Web3(provider as any);
      (web3.currentProvider as any)?.send(
        {
          method: 'eth_sign',
          params: [pubKey[0], message],
          from: pubKey[0],
        },
        (err: Error, result: any) => {
          if (err) {
            return uiConsole(err);
          }
          uiConsole('Eth sign message => true', result);
        }
      );
    } catch (error) {
      console.log('error', error);
      uiConsole('error', error);
    }
  };
  const signV4Message = async () => {
    try {
      const pubKey = (await provider.request({
        method: 'eth_accounts',
      })) as string[];
      const web3 = new Web3(provider as any);
      const chainId = await web3.eth.getChainId();
      const typedData = getV4TypedData(chainId.toString());
      (web3.currentProvider as any)?.send(
        {
          method: 'eth_signTypedData_v4',
          params: [pubKey[0], JSON.stringify(typedData)],
          from: pubKey[0],
        },
        (err: Error, result: any) => {
          if (err) {
            return uiConsole(err);
          }
          uiConsole('Eth sign message => true', result);
        }
      );
    } catch (error) {
      console.log('error', error);
      uiConsole('error', error);
    }
  };

  const getChainId = (): Promise<number> => {
    const web3 = new Web3(provider as any);
    return web3.eth.getChainId();
  }

  return { getAccounts, getBalance, signMessage, signV4Message, personalSign, getChain, getEnsByAddress, getChainId};
};

export default ethProvider;
