import psl from 'psl';

export const getDomain = (host: string) => {
    if (host) {
        const [first] = host.split(".").reverse();
        if (!isNaN(+first)) {
            return host;
        }
        return psl.get(host);
    }
};

export const getHostname = (url: string): string => {
    if (url) {
        const _url = new URL(url);
        return _url.hostname;
    }
    return '';
};

export const formatHostname = (url: string) => {
    return getHostname(url).replace(/[.]/g, '-');
};
