import { BrowserType, DeepLink, PlatformType } from "./deeplink";

export type IMacOS = {
    appName: string;
    appId: string;
};

export default class MacOS implements DeepLink {
    fallbackWebUrl: string;
    storeUrl: string;
    private appName?: string;
    private appId?: string;
    private protocol: string;
    type: PlatformType;
    _browser: BrowserType;
    constructor(props?: IMacOS) {
        this.type = 'ios';
        this.protocol = 'hiseas://';
        this.storeUrl = 'itms-apps://itunes.apple.com/app/';
        this.appName = props?.appName;
        this.appId = props?.appId;
        this._browser = 'Safari';
    }

    public get browser() {
        return this._browser;
    }

    open(url: string) {
        if (url.startsWith(this.protocol)) {
            this.openUrl(url);
        } else {
            this.openUrl(`${this.protocol}${url}`);
        }
    }
    getStoreURL(): string {
        const baseurl = this.storeUrl;
        const appName = this.appName;
        const id = this.appId;
        return (id && appName) ? (baseurl + appName + "/id" + id + "?mt=8") : '';
    }
    openDapp(url: string) {
        if (url.startsWith("browser/url=")) {
            this.open(url);
        } else {
            this.open(`browser/url=${url}`);
        }
    }
    openUrl(url: string) {
        const a = document.createElement("a");
        a.href = url;
        a.target = "_self";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}
