
import { MediaRepository } from "../matrix/net/MediaRepository";
import { INft } from "../platform/web/ui/session/nft/NftTileView";
import { SegmentType } from "./navigation";
import { Options, ViewModel } from "./ViewModel";



export class UserAvatarViewModel extends ViewModel<SegmentType, Options> {
    private _displayName: string;
    private _saveLoading: boolean;
    private _avatarUrl: string;
    private _sessionId: string;
    private _nftList: INft[];
    constructor(options: Options) {
        super(options);
        this._sessionId = options.sessionId;
        this._saveLoading = false;
        this._avatarUrl = ''
        this.init();
    }

    get avatarUrl() {
        return this._avatarUrl
    }

    get saveLoading() {
        return this._saveLoading
    }

    get nftList(){
        return this._nftList
    }

    async init(){
        const {hsApi, sessionInfo} = await this.platform.getConfigBySessionId(this._sessionId)
        const profile = await  hsApi.profile(sessionInfo.userId).response();
        const {avatar_url} = profile
        if(avatar_url){
            const mediaRepository = new MediaRepository({
                homeserver: sessionInfo.homeServer,
                platform: this.platform,
            });
            this._avatarUrl = mediaRepository.mxcUrl(avatar_url) || avatar_url;
        }else{
            this._avatarUrl = `https://source.boringavatars.com/beam/120/${sessionInfo.userId}?colors=02D69E,BC39FF,2C96FF,FF9A1C,106EEC&square`;
        }
        const {wallet_address} = profile;
        this.emitChange('avatarUrl')
        // const wallet_address = "0x905d13367f3bb940072a133c81563f1b1a6779ad";
        this._nftList = await hsApi.getAsset({address: wallet_address, offset: 0, limit: 6}).response();
        this.emitChange('nftList')
    }

    setNft({image}){
        if(image){
            this._avatarUrl = image
        }
        this.emitChange('avatarUrl')
    }

    saveAvatar = async () => {
        this._saveLoading = true;
        this.emitChange('saveLoading');
        const {hsApi, sessionInfo} = await this.platform.getConfigBySessionId(this._sessionId);
        const res = await hsApi.saveAvatar(this._avatarUrl).response();
        if(res?.content_uri){
            await hsApi.updateProfile(sessionInfo.userId, 'avatar_url', {avatar_url: res?.content_uri} )
        }
        this._saveLoading = false;
        this.emitChange('saveLoading');
        this.navigation.push('session', this._sessionId)
    }
}