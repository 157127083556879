import { TemplateView, InlineTemplateView } from "../../general/TemplateView";
import { spinner } from "../../common.js";
import { LoadingView, ViewModel } from "../../../../../lib";

export class UserAvatarView extends TemplateView<ViewModel> {

    renderNftList(nftList, _t, vm){
        const view = nftList && nftList.length>0 ? new InlineTemplateView(vm, (t, vm) => {
            return t.div({ className: "nft-list" }, nftList.map(({nft})=>{
                return t.map(vm => vm.avatarUrl, (avatarUrl, t, vm) => {
                    const {image} = nft;
                    return t.img( {
                        className: `name-image`,
                        src: image,
                        onClick: () => {
                            vm.setNft(nft);
                        },
                    })
                })
            }))
        }) : null;
        return view;
    }

    render(t, vm) {
        return t.div({className: 'user-avatar'}, [
            t.p(
                { className: "title" },
                vm.i18n`How do you want to be avatar?`
            ),
            t.mapView(
                vm => vm.avatarUrl,
                (t) => new InlineTemplateView(vm, (t, vm)=>{
                    return t.img(
                        { className: "avatar-image", src: vm.avatarUrl })
                })
            ),
            t.div({ className: 'nft-title' }, vm.i18n`Use NFT for avatar`),
            t.mapView(
                (vm)=>vm.nftList,
                (nftList)=> this.renderNftList(nftList?.assets, t, vm),
            ),
            t.div({ className: "next-button" }, [
                t.button({
                    className: "next-button-icon",
                    onClick: vm.saveAvatar
                }),
            ]),
            t.mapView(vm => vm.saveLoading, (saveLoading) => {
                return saveLoading ? new InlineTemplateView(vm, (t, vm)=>{
                    return t.div({
                        className: {
                            loading: true,
                        }
                    }, [
                        spinner(t),
                        t.div({className: "description"}, vm.i18n`Saving avatar…`)
                    ])
                }) : null;
            })
        ]);
    }
}
