import {
    ADAPTER_EVENTS,
    CustomChainConfig,
    SafeEventEmitterProvider,
    WALLET_ADAPTERS,
    WALLET_ADAPTER_TYPE,
} from "@web3auth/base";
import type { LOGIN_PROVIDER_TYPE } from "@toruslabs/openlogin";

import { Web3AuthCore } from "@web3auth/core";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { WalletConnectV1Adapter } from "@web3auth/wallet-connect-v1-adapter";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { NetworkSwitch } from '@web3auth/ui'
import { CHAIN_CONFIG, CHAIN_CONFIG_TYPE } from "./chainConfig";
import { WEB3AUTH_NETWORK_TYPE } from "./web3AuthNetwork";
import { getWalletProvider, IWalletProvider } from "./walletProvider";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { ADAPTER_CACHE_KEY, IWeb3AuthProps } from "./web3auth";
import { EventEmitter } from "events";
import { openMetaMaskUrl } from "../platform/web/ui/general/utils";

const uiConsole = (...args: unknown[]): void => {
    const el = document.querySelector("#console>p");
    if (el) {
        el.innerHTML = JSON.stringify(args || {}, null, 2);
    }
};

export { CHAIN_CONFIG }

export const WALLET_CONNECT_BRIDGE = "wss:///bridge.sending.me/";

export class Web3AuthProvider extends EventEmitter {
    private _user: unknown | null;
    private _walletProvider: IWalletProvider | null;
    private _currentChainConfig: CustomChainConfig;
    private clientId;
    private autoConnect;
    private chain: CHAIN_CONFIG_TYPE;
    private _web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
    private _web3Auth: Web3AuthCore | null;
    constructor(props: IWeb3AuthProps) {
        super();
        this.chain = props.chain;
        this._web3AuthNetwork = props.web3AuthNetwork;
        this.autoConnect = props.autoConnect;
        this._user = null;
        this._web3Auth = null;
        this._walletProvider = null;
        this._currentChainConfig = CHAIN_CONFIG[props.chain];
        this.clientId =
            props.clientId ||
            "BMOX818DnItYrf06YfCdWRfKHgGOqh4D3vy0zcmJxKFtklrFKp5z3KbUMDQ4zg0sDNR8HyAfe3_jRX8TNEkY5N4";
        this.init();
        this._listeners();
    }

    get currentChainConfig() {
        return this._currentChainConfig
    }

    get currentAdapter(){
        this._web3Auth?.provider;
        return this._web3Auth
    }

    private setWalletProvider = (
        web3authProvider: SafeEventEmitterProvider
    ) => {
        const walletProvider = getWalletProvider(
            this.chain,
            web3authProvider,
            uiConsole
        );
        this._walletProvider = walletProvider;
        return walletProvider;
    };

    private _listeners = () => {
        // this.on("initializing", this.onInitializing);
    };

    private getAdapters(){
        const networkUi = new NetworkSwitch();
        const metamaskAdapter = new MetamaskAdapter({
            chainConfig: this.currentChainConfig,
            clientId: this.clientId,
        });
        const wcAdapter = new WalletConnectV1Adapter({
            adapterSettings: {
                qrcodeModal: QRCodeModal,
                networkSwitchModal: networkUi,
                bridge: WALLET_CONNECT_BRIDGE,
            },
            chainConfig: this.currentChainConfig,
        });
        return [metamaskAdapter, wcAdapter]
    }

    private init = async () => {
        try {
            this.emit("initializing", true);
            if (!this.autoConnect) {
                localStorage.removeItem(ADAPTER_CACHE_KEY);
            }
            const web3AuthInstance = new Web3AuthCore({
                chainConfig: this.currentChainConfig,
                enableLogging: true,
                clientId: this.clientId,
            });
            this.subscribeAuthEvents(web3AuthInstance);
            
            const adapters = this.getAdapters();
            adapters.forEach((adapter)=>{
                web3AuthInstance.configureAdapter(adapter)
            });

            await web3AuthInstance.init();
            this._web3Auth = web3AuthInstance;
        } catch (error) {
            console.error(error);
        } finally {
            this.emit("initializing", false);
        }
    };

    subscribeAuthEvents = (web3auth: Web3AuthCore) => {
        // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
        web3auth.on(ADAPTER_EVENTS.CONNECTED, (data: unknown) => {
            console.log("Yeah!, you are successfully logged in", data);
            this._user = data;
            this.setWalletProvider(web3auth.provider!);
            this.emit(ADAPTER_EVENTS.CONNECTED);
        });

        web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
            console.log("connecting");
        });

        web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
            console.log("disconnected");
            this._walletProvider = null;
            this._user = null;
        });

        web3auth.on(ADAPTER_EVENTS.ADAPTER_DATA_UPDATED, (data: unknown) => {
            console.log("ADAPTER_DATA_UPDATED", data);
        });

        web3auth.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
            console.error(
                "some error or user has cancelled login request",
                error
            );
        });
    };

    public get provider() {
        return this._walletProvider;
    }

    public get web3Auth() {
        return this._web3Auth;
    }

    public login = async (
        adapter: WALLET_ADAPTER_TYPE,
        loginProvider?: LOGIN_PROVIDER_TYPE,
        login_hint?: string,
        params?: any,
    ) => {
        try {
            console.log(adapter, params)
            this.emit("onLogging", true);
            if (!this._web3Auth) {
                console.log("web3auth not initialized yet");
                uiConsole("web3auth not initialized yet");
                return;
            }
            let localProvider;
            if (adapter === WALLET_ADAPTERS.METAMASK) {
                localProvider = await this._web3Auth.connectTo(adapter);
            } else {
                localProvider = await this._web3Auth.connectTo(adapter, {
                    loginProvider,
                    login_hint,
                });
            }
            this.setWalletProvider(localProvider!);
        } catch (error) {
            console.log("error", error);
            console.log(adapter, WALLET_ADAPTERS.METAMASK, adapter === WALLET_ADAPTERS.METAMASK);
            console.log(error.code, error.code === 5111)
            if(adapter === WALLET_ADAPTERS.METAMASK && error.code === 5111){
                let path = '#/'
                if(params?.invitePath){
                    path = params?.invitePath;
                }
                openMetaMaskUrl(`https://metamask.app.link/dapp/m.sending.me/${path}?nac=${params.nac}`)
            }
            throw error;
        } finally {
            this.emit("onLogging", false);
        }
    };

    public loginWithWalletConnect = async () => {
        try {
            this.emit("onLogging", true);
            if (!this._web3Auth) {
                console.log("web3auth not initialized yet");
                uiConsole("web3auth not initialized yet");
                return;
            }
            const localProvider = await this._web3Auth.connectTo(
                WALLET_ADAPTERS.WALLET_CONNECT_V1,
                {}
            );
            return this.setWalletProvider(localProvider!);
        } catch (error) {
            console.log("error", error);
            throw error;
        } finally {
            this.emit("onLogging", false);
        }
    };

    public logout = async () => {
        if (!this._web3Auth) {
            console.log("web3auth not initialized yet");
            uiConsole("web3auth not initialized yet");
            return;
        }
        await this._web3Auth.logout();
        this._walletProvider = null;
    };

    // private static internalInstance: Web3AuthProvider;

    // public static get instance(): Web3AuthProvider {
    //     if (!Web3AuthProvider.internalInstance) {
    //         Web3AuthProvider.internalInstance = new Web3AuthProvider({
    //             chain: "mainnet",
    //             web3AuthNetwork: "mainnet"
    //         });
    //     }
    //     return Web3AuthProvider.internalInstance;
    // }
}
