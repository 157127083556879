import copy from "copy-to-clipboard";
import {DeepLinkApp} from '../../platform/web/deeplink'
import {Options as BaseOptions, ViewModel} from "../ViewModel";
import {SegmentType} from "../navigation/index";

type Options = { sessionId: string; } & BaseOptions;

const SENDING_ME_APP_URL = 'https://app.sending.me';

export class DownloadViewModel extends ViewModel<SegmentType, Options> {
    private _showDownload: boolean;
    private _sessionId: string;

    constructor(options: Options) {
        super(options);
        this._showDownload = true;
        this._sessionId = options?.sessionId;
    }

    get showDownload(): boolean {
        return this._showDownload;
    }

    open() {
        const roomid = this.options.navigation.path.get('room')?.value     
        if(roomid){
            location.href = `${SENDING_ME_APP_URL}/deeplink?url=hiseas://squad/room/roomid=${roomid}`
        }else {
            const shareStr = localStorage.getItem('invitePath')
            if(shareStr){
                const [hash, type, id] = shareStr.split('/');
                let url;
                if(hash === '#' && type && id){
                    if(type === 'user'){
                        url = `hiseas://user/userid=${id}`
                        copy(url)
                    }else if(type === 'room'){
                        url = `hiseas://squad/room/roomid=${id}`;
                        copy(url)
                    }
                }
                if(url){
                    this.openUrl(`${SENDING_ME_APP_URL}/deeplink?url=${url}`)
                }
            }else{
                this.openUrl('${SENDING_ME_APP_URL}/deeplink?url=hiseas://')
            }
        }
    }

    download() {
        const shareStr = localStorage.getItem('invitePath')
        if(shareStr){
            const [hash, type, id] = shareStr.split('/');
            let url;
            if(hash === '#' && type && id){
                if(type === 'user'){
                    url = `hiseas://user/userid=${id}`
                    copy(url)
                }else if(type === 'room'){
                    url = `hiseas://squad/room/roomid=${id}`;
                    copy(url)
                }
            }
        }
        if(DeepLinkApp.instance.isIos){
            this.openUrl('https://testflight.apple.com/join/nGIMz8oy');
        }else if(DeepLinkApp.instance.isAndroid){
            this.openUrl(`${SENDING_ME_APP_URL}/andriod/release/linx.apk`);
        }
    }

    openUrl(url) {
        const a = document.createElement("a");
        a.href = url;
        a.target = "_self";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}