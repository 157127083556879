import {TemplateView, InlineTemplateView} from "./general/TemplateView";
import {spinner} from "./common.js";

export class AuthView extends TemplateView<any> {
    render(t, vm) {

        const confirmView = new InlineTemplateView(vm, t => {
            return t.div({className: 'authorize-view' },[
                t.div({className: 'title'}, vm.i18n`Authorize access our Sending.Me account!`),
                t.p({ className: 'description' }, vm.i18n`Read your profile info (avatar | display name)`),
                t.div({ className: "button-column" }, [
                    t.button({
                        className: "button-action primary",
                        type: "submit",
                        onClick: () => vm.onAuthorize(),
                    }, vm.i18n`Authorize`),
                    t.button({
                        className: "button-action second",
                        type: "submit",
                        onClick: () => {history.back()},
                    }, vm.i18n`Reject`),
                ]),
            ]);
        });
        const progressView = new InlineTemplateView(vm, t => {
            return t.p({className: "status", hidden: vm => !vm.showStatus}, [
                spinner(t, {hidden: vm => !vm.busy}), t.span(vm => vm.status)
            ]);
        });

        return t.div({className: "LogoutScreen"}, [
            t.div({className: "content"}, [
                t.mapView(vm => true, showConfirm => {
                    return showConfirm ? confirmView : progressView;
                })
            ]),
        ]);
    }
}
