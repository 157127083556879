/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { Options as BaseOptions, ViewModel } from "./ViewModel";
import { SegmentType } from "./navigation/index";
import { HomeServerApi } from "../matrix/net/HomeServerApi";
import { DisplayNameViewModel } from "./DisplayNameViewModel";
import { UserAvatarViewModel } from "./UserAvatarViewModel";

type Options = { path: Paths } & BaseOptions;

export enum Paths {
    "display-name" = "display-name",
    "avatar" = "avatar",
}

export const PROFILE_STEP = "sdm_user_profile_step";

export class ProfileViewModel extends ViewModel<SegmentType, Options> {
    private _busy: boolean;
    private _error?: Error;
    private _path: Paths;
    private _displayNameViewModel;
    private _userAvatarViewModel;

    constructor(options: Options) {
        super(options);
        this._busy = false;
        this._error = undefined;
        this._path = this.navigation.path.get("profile")?.value;
        this.initProfile();
    }

    get busy(): boolean {
        return this._busy;
    }

    get path(): Paths {
        return this._path;
    }

    get displayNameViewModel(){
        return this._displayNameViewModel;
    }
    
    get userAvatarViewModel(){
        return this._userAvatarViewModel;
    }

    async initProfile() {
        const sessionId = this.navigation.path.get('session')?.value;
        if (this._path === Paths["display-name"]) {
            if (this._displayNameViewModel) {
                this.disposeTracked(this._displayNameViewModel);
            }
            this._displayNameViewModel = this.track(
                new DisplayNameViewModel(
                    this.childOptions({
                        profileVM: this,
                        sessionId
                    })
                )
            );
            this.emitChange("DisplayNameViewModel");
        } else if (this._path === Paths.avatar) {
            if (this._userAvatarViewModel) {
                this.disposeTracked(this._userAvatarViewModel);
            }
            this._userAvatarViewModel = this.track(
                new UserAvatarViewModel(this.childOptions({
                    profileVM: this,
                    sessionId,
                }))
            );
            this.emitChange("UserAvatarViewModel");
        }
    }
}
